<template>
  <BaseDialog
    ref="newProjectDialog"
    data-cy="new-project-dialog"
    :p_title="m_project.id != null ? 'Editer un projet' : 'Nouveau projet'"
    :p_subtitle="
      isCurrentUserBlocked()
        ? ''
        : 'Renseignez les informations de votre patient pour créer votre projet.'
    "
  >
    <w-form
      ref="formNewProject"
      id="formNewProject"
      v-model="m_isFormValid"
      @submit.prevent="validate()"
    >
      <w-card-text v-if="!isCurrentUserBlocked()">
        <!-- Rechercher un client -->
        <div
          class="mb-4"
          v-if="p_privilege == Privilege.ADMIN"
        >
          <w-autocomplete
            data-cy="client-autocomplete"
            v-model="m_clientId"
            :items="m_clients"
            :item-title="(item) => item.lastName + ' ' + item.firstName"
            :item-value="(item) => item.id"
            :rules="[(v) => (v && v > -1) || 'Client requis']"
            @change="updatePatientList()"
            no-data-text="Aucun client"
            label="Rechercher un client"
            hide-details
          ></w-autocomplete>
        </div>

        <!-- Patient -->
        <div class="mb-4">
          <div class="section-label">Patient</div>

          <w-radio-group
            v-model="m_isNewPatient"
            inline
            hide-details
            color="primary"
          >
            <w-radio
              label="Nouveau patient"
              :value="true"
            />
            <w-radio
              class="ml-4"
              label="Patient existant"
              :value="false"
              @click="fetchPatients()"
            />
          </w-radio-group>
          <!-- Rechercher un patient -->
          <div v-if="!m_isNewPatient">
            <w-autocomplete
              v-model="m_patientId"
              :items="m_patients"
              :item-title="(item) => item.lastName + ' ' + item.firstName"
              :item-value="(item) => item.id"
              :rules="[(v) => (v && v > -1) || 'Patient requis']"
              no-data-text="Aucun patient"
              label="Rechercher un patient"
              hide-details
              :loading="m_fetchingPatients"
              :disabled="m_fetchingPatients"
            ></w-autocomplete>
          </div>
          <!-- Nouveau patient -->
          <w-row
            class="ma-0"
            v-else
          >
            <w-col
              md="6"
              class="ma-0 pa-0 pr-2"
            >
              <w-text-field
                data-cy="patient-last-name"
                v-model.trim="m_patient.lastName"
                label="Nom du patient"
                :rules="[(v) => (v && !!v.trim()) || 'Nom requis']"
                required
                color="primary"
                hide-details
              ></w-text-field>
            </w-col>
            <w-col
              md="6"
              class="ma-0 pa-0 pl-2"
            >
              <w-text-field
                data-cy="patient-first-name"
                v-model.trim="m_patient.firstName"
                label="Prénom du patient"
                :rules="[(v) => (v && !!v.trim()) || 'Prénom requis']"
                required
                color="primary"
                hide-details
              ></w-text-field>
            </w-col>
          </w-row>
        </div>

        <!-- Maxillaire / Mandibule -->
        <div class="mb-6">
          <div class="section-label">Attelles commandées</div>
          <div class="d-flex justify-space-around">
            <w-switch
              data-cy="maxilla-switch"
              v-model="m_maxilla"
              label="Maxillaire"
              :rules="[(v) => hasAtLeastOneArcade() || 'Requis']"
              color="primary"
              hide-details
              density="compact"
            />

            <w-switch
              data-cy="mandible-switch"
              v-model="m_mandible"
              label="Mandibule"
              :rules="[(v) => hasAtLeastOneArcade() || 'Requis']"
              color="primary"
              hide-details
              density="compact"
            />
          </div>
        </div>

        <!-- Date de livraison -->
        <w-row class="mb-4">
          <w-col md="12">
            <div data-cy="delivery-date-picker">
              <DatePicker
                @change="changeInstallationDate"
                :p_privilege="p_privilege"
                :p_date="m_project.installationDate"
                :p_min="m_minDate"
                :p_max="m_maxDate"
                :p_label="
                  p_privilege === Privilege.IN_OFFICE
                    ? 'Date de pose'
                    : 'Date de livraison'
                "
                :notempty="true"
                required
                :p_allowedDates="isDateEnabled"
                :hidedetails="true"
              />
            </div>
          </w-col>
        </w-row>

        <!-- platform switch -->
        <div v-if="p_privilege === Privilege.ADMIN">
          <div class="section-label">Provient d'une plateforme</div>
          <div class="d-flex justify-space-around">
            <w-switch
              v-model="m_itero"
              label="My iTero"
              hide-details
              color="primary"
              density="compact"
              @change="switchScanner('itero')"
            ></w-switch>

            <w-switch
              v-model="m_threeshape"
              label="3Shape"
              hide-details
              color="primary"
              density="compact"
              @change="switchScanner('threeshape')"
            ></w-switch>
          </div>
        </div>
      </w-card-text>

      <div>
        <w-alert
          v-if="isCurrentUserBlocked()"
          data-cy="blocked-account-alert"
          class="my-3 mt-6"
          type="error"
          prominent
          elevation="2"
        >
          <strong>Votre compte est actuellement bloqué</strong> car vous n'avez
          plus de jetons.
          <br />
          <w-divider class="my-2"></w-divider>
          <w-icon :icon="mdiEmailOutline"></w-icon> :
          <a href="mailto:contact@winnovemed.com">contact@winnovemed.com</a>
          <br />
          <w-icon :icon="mdiPhone"></w-icon> :
          <a href="tel:+33244848644">+33 2 44 84 86 44</a>
        </w-alert>
        <w-alert
          v-else-if="m_tokensLeft < 5 && m_tokensLeft > 0"
          data-cy="new-project-tokens-warning"
          class="my-3 mt-6"
          type="warning"
          prominent
          elevation="2"
        >
          Il vous reste moins de 5 jetons. Pensez à en recommander pour ne pas
          être bloqué.
        </w-alert>
        <w-alert
          v-else-if="m_tokensLeft <= 0"
          data-cy="new-project-tokens-negative"
          class="my-3 mt-6"
          type="error"
          prominent
          elevation="2"
        >
          Vous n'avez plus de jetons. Pensez à en recommander pour ne pas être
          bloqué.
        </w-alert>
      </div>

      <w-card-actions class="justify-end">
        <w-btn
          v-if="isCurrentUserBlocked()"
          data-cy="go-to-billing-button"
          :to="{ name: ROUTE_BILLING }"
          @click="close()"
          depressed
          :append-icon="mdiCashClock"
          >Voir ma consommation
        </w-btn>
        <w-btn
          v-else
          data-cy="submit-new-project-button"
          :disabled="!m_isFormValid"
          type="submit"
          depressed
          class="px-10"
          >Valider
        </w-btn>
      </w-card-actions>
    </w-form>
  </BaseDialog>
</template>

<script lang="ts">
import BaseDialog from "@/components/shared/BaseDialog.vue";
import DatePicker from "@/components/shared/DatePicker.vue";
import { AuthHelper } from "@/helpers/AuthHelper";
import BillingHelper from "@/helpers/BillingHelper";
import DateHelper from "@/helpers/DateHelper";
import OrderHelper from "@/helpers/OrderHelper";
import PatientHelper from "@/helpers/PatientHelper";
import ProjectHelper from "@/helpers/ProjectHelper";
import UserHelper from "@/helpers/UserHelper";
import Config from "@/models/Config";
import Patient from "@/models/Patient";
import Project from "@/models/Project";
import User from "@/models/User";
import router, { ROUTE_BILLING, ROUTE_EDITOR } from "@/router";
import { ColorsConstants } from "@/shared/constants";
import { Arcade, Privilege, Role } from "@winnove/vue-wlib/enums";
import { mdiCashClock, mdiEmailOutline, mdiPhone } from "@mdi/js";
import { DateTime } from "luxon";
import { useRepo } from "pinia-orm";
import { computed, defineComponent, onMounted, ref, watch } from "vue";

export default defineComponent({
  name: "NewProjectDialog",
  components: {
    DatePicker,
    BaseDialog,
  },
  props: {
    p_privilege: {
      type: Number as () => Privilege,
      required: true,
    },
    p_role: {
      type: Number as () => Role,
      required: true,
    },
  },
  setup(props, { emit }) {
    // exported refs
    const formNewProject = ref<HTMLFormElement | null>(null);
    const m_isFormValid = ref(true);
    const m_doctor = ref("");
    const m_isNewPatient = ref(true);
    const m_patient = ref(new Patient());
    const m_patientId = ref(null as number | null);
    const m_patients = ref<Patient[]>([]);
    const m_clientId = ref(null as number | null);
    const m_clients = ref<User[]>([]);
    const m_project = ref(new Project());
    const m_minDate = ref("");
    const m_maxDate = ref("");
    const m_maxilla = ref(false);
    const m_mandible = ref(false);
    const m_showDatePicker = ref(false);
    const m_tokensLeft = ref(10);
    const newProjectDialog = ref<InstanceType<typeof BaseDialog> | null>(null);
    const m_itero = ref(false);
    const m_threeshape = ref(false);
    const m_fetchingPatients = ref(false);

    // private refs
    const m_productionOverload = ref<boolean[]>(
      new Array<boolean>(53).fill(false)
    );
    const m_closedDays = ref<string[]>([]);

    const isAdmin = computed((): boolean => {
      return props.p_privilege == Privilege.ADMIN;
    });

    const isAccountManager = computed((): boolean => {
      return props.p_role == Role.ACCOUNT_MANAGER;
    });

    onMounted(() => {
      m_doctor.value = AuthHelper.getLoggedUser().lastName.toUpperCase();
      m_maxDate.value = DateHelper.getLastAvailableDate();
      if (!isAdmin.value) {
        m_minDate.value = DateHelper.getNextAvailableDate();
      }
      if (isAccountManager.value) {
        let dayOffset = new Date().getHours() < 12 ? 0 : 1;
        m_minDate.value = DateHelper.getNextBusinessDay(dayOffset);
      }
    });

    // Indicates wether a day can be selected for the installation or not.
    function isDateEnabled(val: DateTime): boolean {
      if (!val) return false;
      const date = val;

      return (
        !m_closedDays.value.includes(date.toFormat("yyyy-MM-dd")) && // Do not allow closed days
        (props.p_privilege == Privilege.ADMIN ||
          !m_productionOverload.value[DateHelper.getWeekNumber(date) - 1]) && // Do not allow overload weeks, for non-admin users
        date.weekday != 6 && // Do not allow Saterday
        date.weekday != 7 // Do not allow Sunday
      );
    }

    async function show(): Promise<void> {
      // Get all necessary data to compute disabled delivery days
      const productionCapacity = Config.getProductionCapacity();
      m_closedDays.value = Config.getClosedDays();
      const productionCharge: Array<number> =
        await OrderHelper.getProductionCharge();

      // Computing full production weeks according to the capacity and the current charge.
      for (let cpt: number = 0; cpt < 53; cpt++) {
        m_productionOverload.value[cpt] =
          productionCapacity[cpt] <= productionCharge[cpt];
      }

      // Load patients.
      updatePatientList();

      m_isNewPatient.value = true;
      m_patientId.value = null;

      // Load clients.
      m_clients.value = useRepo(User).all();
      if (m_clients.value.length === 0) {
        await UserHelper.fetchUsers();
        m_clients.value = useRepo(User).all();
      }
      m_clientId.value = null;

      newProjectDialog.value?.show();

      if (
        props.p_privilege === Privilege.FREEMIUM ||
        props.p_privilege === Privilege.PREMIUM
      ) {
        const userId = AuthHelper.getLoggedUser().id!;
        const response = await BillingHelper.getRemainingTokens(userId);

        // Assign the value of credits to m_tokensLeft
        if (response && response.remaining && response.remaining.length > 0) {
          m_tokensLeft.value = parseInt(response.remaining[0].credits, 10);
        } else {
          m_tokensLeft.value = 0;
        }
      }
    }

    function close(): void {
      m_patient.value = new Patient();
      m_project.value = new Project();
      formNewProject.value?.reset();
      newProjectDialog.value?.close();
    }

    function hasAtLeastOneArcade(): boolean {
      return m_maxilla.value || m_mandible.value;
    }

    function isFromItero(): boolean {
      return m_itero.value;
    }

    function isFrom3Shape(): boolean {
      return m_threeshape.value;
    }

    function switchScanner(scanner: string) {
      if (scanner === "itero" && m_itero.value) {
        m_threeshape.value = false; // Désactive m_threeshape si m_itero est activé
      } else if (scanner === "threeshape" && m_threeshape.value) {
        m_itero.value = false; // Désactive m_itero si m_threeshape est activé
      }
    }

    async function fetchPatients() {
      m_fetchingPatients.value = true;
      await PatientHelper.fetchPatients();
      updatePatientList();
      m_fetchingPatients.value = false;
    }

    function updatePatientList(): void {
      let userId = AuthHelper.getLoggedUser().id;
      if (props.p_privilege == Privilege.ADMIN && m_clientId.value != null) {
        userId = m_clientId.value;
      }
      m_patients.value = useRepo(Patient).query().where("userId", userId).get();
    }

    function changeInstallationDate(p_date: string): void {
      m_project.value.installationDate = p_date;
    }

    async function validate(): Promise<void> {
      // Create the new session and launch the editor.
      let userId: number = AuthHelper.getLoggedUser().id!;

      // Client (Admin can select which client the project is for)
      if (props.p_privilege == Privilege.ADMIN) {
        userId = m_clientId.value!;
      }

      // Patient.
      if (m_isNewPatient.value) {
        // update patient owner
        m_patient.value.userId = userId;
        // Create new patient.
        await PatientHelper.addPatient(m_patient.value as Patient).then(
          (ret_patient: Patient) => {
            Object.assign(m_patient.value, ret_patient);
            m_patientId.value = m_patient.value.id;
          }
        );
      }

      // Project

      // Set itero UUID
      if (isFromItero()) {
        m_project.value.iteroId = "itero-" + m_project.value.reference;
      }

      // Set itero UUID
      if (isFrom3Shape()) {
        m_project.value.threeShapeId = "3shape-" + m_project.value.reference;
      }

      // Assign existing patient to the project
      m_project.value.patientId = m_patientId.value;

      await ProjectHelper.createProject(m_project.value as Project).then(
        (ret_project: Project) => {
          Object.assign(m_project.value, ret_project);
        }
      );

      // Orders

      if (m_maxilla.value) {
        await OrderHelper.createOrder(m_project.value.id!, Arcade.MAXILLA);
      }

      if (m_mandible.value) {
        await OrderHelper.createOrder(m_project.value.id!, Arcade.MANDIBLE);
      }

      // Go to editor
      router.push({
        name: ROUTE_EDITOR,
        params: { reference: m_project.value.reference },
      });
    }

    // watch for changes in m_maxilla and m_mandible
    // to validate the form
    watch([m_maxilla, m_mandible], () => {
      formNewProject.value?.validate();
    });

    function isCurrentUserBlocked() {
      const user = useRepo(User)
        .query()
        .where("id", AuthHelper.getLoggedUser().id)
        .first();
      if (!user) {
        return false;
      }
      if (
        user.privilege === Privilege.ADMIN ||
        user.privilege === Privilege.IN_OFFICE
      )
        return false;
      return !!user.blocked;
    }

    return {
      newProjectDialog,
      Privilege,
      ColorsConstants,
      formNewProject,
      m_isFormValid,
      m_doctor,
      m_isNewPatient,
      m_patient,
      m_patientId,
      m_patients,
      m_clientId,
      m_clients,
      m_project,
      m_minDate,
      m_maxDate,
      m_maxilla,
      m_mandible,
      m_showDatePicker,
      m_tokensLeft,
      m_itero,
      m_threeshape,
      ROUTE_BILLING,
      m_fetchingPatients,
      isDateEnabled,
      show,
      close,
      hasAtLeastOneArcade,
      updatePatientList,
      changeInstallationDate,
      validate,
      isCurrentUserBlocked,
      fetchPatients,
      switchScanner,
      mdiEmailOutline,
      mdiPhone,
      mdiCashClock,
    };
  },
});
</script>

<style lang="scss" scoped>
.section-label {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: normal;
}
</style>
